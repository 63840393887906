.plan-table,
tr,
th,
td {
    border-collapse: collapse;
}

/* th styel  */
.plan-table th {
    width: 25%;
}

.th-plan-title {
    padding-left: 30px;
}
.head-plan-title {
    border-bottom: 1px solid #99999950;
}
.head-plan-title span {
    font-size: 30px;
    font-weight: 600;
}

.th-plan {
    background-color: #fff;
    padding: 30px;
    border: 1px solid #99999960;
}

.th-plan.active {
    background-color: #f1f1f1;
}

.plan-type {
    font-size: 16px;
    color: #666;
    font-weight: 600;
    text-transform: capitalize;
}

.ft-box {
    position: relative;
    top: 50px;
}

.ft-title {
    text-align: start;
    font-size: 40px;
    line-height: 1.2;
}
.ft-subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.ft-subtitle span {
    font-size: 18px;
    font-weight: 600;
    padding-left: 10px;
}

/* table body  */
.plan-body tr td {
    padding: 15px;
}

.plan-body,
.plan-subtitle {
    background-color: #fff;
}

.plan-body td.active,
.blank {
    background-color: #f1f1f1;
}

.plan-body tr td {
    border: 1px solid #99999960;
}

.plan-title {
    background-color: #E4E5E7;
}

/* 
.plan-subtitle {
    background-color: #fff;
} */

/* .blank {
    background-color: #f1f1f1;
} */

.plan-btn-gp {
    background-color: #fff;
    border-radius: 30px;
    padding: 16px 10px;
}

.plan-btn {
    color: #000;
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
}

.plan-btn.active {
    background-color: #007b5c;
    color: #fff;
}

.plan-td{
    display: flex;
    justify-content: center;
    align-items: center;
}

.free-trial {
    margin-top: 20px;
    border: none;
    color: #fff;
    background-color: #7620ff;
    border-radius: 10px;
    padding: 8px 15px;
    outline: none;
    cursor: pointer;
}